<template>
<div class="flex justify-between w-full bg-gray-300">
  <div class="m-6 flex rounded-md shadow-sm w-1/3">
    <div class="relative flex items-stretch flex-grow focus-within:z-10">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input :value="form.title" @input="$emit('change:title', $event.target.value)" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-md pl-10 sm:text-sm border-gray-300 dark:bg-primaryDark dark:text-white" placeholder="Search..." />
    </div>
  </div>
  <SelectInput class="w-1/5 m-6" @change="$emit('change:department', $event.target.value)" :value="form.department" :options=departmentTypes placeholder="Department" />
  <SelectInput class="w-1/5 m-6" @change="$emit('change:type', $event.target.value)" :value="form.type" :options="documentTypes" placeholder="Type"/>
</div>
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import SelectInput from "@/components/inputs/SelectInput";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "DocumentTableHeader",
  components: {SelectInput, SearchIcon },
  props: {
    form: Object
  },
  setup() {
    const store = useStore()

    return {
      departmentTypes: computed(() => store.getters.getDepartmentTypes),
      documentTypes: computed(() => store.getters.getDocumentTypes)
    }
  }
}
</script>
