<template>
  <div class="flex justify-evenly bg-white p-6 w-full rounded-md">
    <div v-for="img in images" :key="img.id" class=" m-2 w-full h-max">
      <img :src="img.image" class="h-full object-cover"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageDisplay",
  props: {
    images: Array
  }
}
</script>
