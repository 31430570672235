<template>
  <div>
    <div v-if="!editModeActive">
      <div class="my-10 bg-white p-6">
        <div class="flex items-center h-16">
          <PencilIcon v-if="edit" @click="editModeActive=true" class="h-1/3 mr-2 cursor-pointer"/>
          <h2 class="text-xl font-medium">About</h2>
        </div>

        <div class="my-4">
          <p class="text-lg text-gray-400">Work:</p>
          <p class="text-lg">{{ production.title }}</p>
        </div>
        <div class="my-4 flex justify-between">
          <div>
            <p class="text-lg text-gray-400">Premiere date:</p>
            <p class="text-lg">{{ production.datePremiered }}</p>
          </div>
          <div class="pr-10">
            <p class="text-lg text-gray-400">Runtime:</p>
            <p class="text-lg">{{production.runtime}}</p>
          </div>

        </div>
        <div class="my-4 flex justify-between">
          <div>
            <p class="text-lg text-gray-400">Total shows:</p>
            <p class="text-lg">{{ production.totalPerformances }}</p>
          </div>
          <div class="pr-10">
            <p class="text-lg text-gray-400">Type:</p>
            <p class="text-lg">{{production.workType}}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <teleport to="body">
        <div class="bg-white fixed z-10 inset-x-80 inset-y-40 p-10 border-2 shadow-md overflow-y-auto flex justify-evenly items-start flex flex-col">
          <h1 class="font-medium">Edit about info</h1>
          <div class="flex">
            <DateInput class="w-2/5 m-2" heading="Premiere date" :value="form.datePremiered" @update:value="form.datePremiered=$event" />
            <DateInput class="w-2/5 m-2" heading="Final show date" :value="form.finalPerformance" @update:value="form.finalPerformance=$event" />
            <SmallTextInput class="w-2/5 m-2" heading="Total shows" :value="form.totalPerformances" @update:value="form.totalPerformances=$event" />
            <SmallTextInput heading="Running time" class="w-2/5 m-2 " :value="form.runtime" @update:value="form.runtime=$event"/>
          </div>
          <SelectInput class="w-2/5 m-2 col-span-6 sm:col-span-3" heading="Type" :value="form.workType" @update:value="form.workType=$event" :options="workTypes"/>
          <div class="flex justify-end w-full">
            <button @click="editModeActive=false" type="submit" class="mr-2 bg-white-600 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
              Cancel
            </button>
            <button @click="submit" class="bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
              Save
            </button>
          </div>
        </div>
      </teleport>
    </div>
  </div>
</template>

<script>
import { PencilIcon } from '@heroicons/vue/outline';
import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import useVuelidate from "@vuelidate/core";
import DateInput from "@/components/inputs/DateInput";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import SelectInput from "@/components/inputs/SelectInput";

export default {
  name: "ProductionAbout",
  components: {SelectInput, SmallTextInput, DateInput, PencilIcon},
  props: {
    production: Object,
    edit: Boolean
  },
  setup(props){
    const store = useStore()

    const form = reactive({
      datePremiered: props.production.datePremiered,
      finalPerformance: props.production.finalperformance,
      totalPerformances: props.production.totalPerformances,
      runtime: props.production.runtime,
      workType: props.production.workType
    })

    const rules = {}

    const v$ = useVuelidate(rules, form, {$lazy: true})

    const editModeActive = ref(false)

    return {
      v$,
      form,
      editModeActive,
      workTypes: computed(() => store.getters.getWorkTypes),
      async submit() {
        store.dispatch('saveProduction', {id: store.getters.getProductionId, ...form})
      }
    }
  }
}
</script>
