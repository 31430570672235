<template>
  <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-800 bg-gray-200">
    <thead class=" dark:bg-primaryDark border-b border-gray-300">
    <tr>
      <th v-for="(field, idx) in headers" :key="idx" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-white">
        <button @click="$emit('sort', field)"><SwitchVerticalIcon class="h-4 w-4 inline-block"/> {{ field }}</button>
      </th>
    </tr>
    </thead>
    <tbody>
    <transition-group name="move-unit">
      <DocumentTableUnit v-for="(item, idx) in documents" :key="item.id" :item="item" :fields="headers" :class="idx % 2 === 0 ? 'bg-white dark:bg-primaryDark' : 'bg-gray-50 dark:bg-backgroundDark'"/>
    </transition-group>
    </tbody>
  </table>
</template>

<script>
import {SwitchVerticalIcon} from '@heroicons/vue/solid'
import DocumentTableUnit from "@/components/widgets/documentTable/DocumentTableUnit";
export default {
  name: "DocumentTableContent",
  props: {
    documents: Array,
    headers: Array
  },
  components: {DocumentTableUnit, SwitchVerticalIcon }
}
</script>
