<template>
  <div @click="close" class="flex flex-wrap justify-evenly bg-white ">
      <div v-for="(img,idx) in images" :key="img.id" class=" w-32 h-32 m-2 shadow-md">
        <img @click.stop="open(img.image)" :src="img.image" class="object-cover w-full h-full" />
        <div v-if="edit && isCreator">
          <input type="checkbox" :value="idx" v-model="checkedImages" @change="emitImages"/>
        </div>
      </div>
      <div v-if="modalOpen">
        <teleport to="body">
          <PhotoGalleryModal @close="close" :image="currentImage" />
        </teleport>
      </div>
  </div>
</template>

<script>
import {computed, ref,} from "vue";
import PhotoGalleryModal from "@/components/widgets/photoGallery/PhotoGalleryModal";
import {useStore} from "vuex";

export default {
  name: "PhotoGallery",
  components: {PhotoGalleryModal},
  props: {
    images: Array,
    edit: Boolean,
  },
  data(){
   return {
     checkedImages: [],
   }
  },
  methods: {
    emitImages() {
      this.$emit('update:parent',this.checkedImages)
      }
    },
  setup() {
    const modalOpen = ref(false)
    const currentImage = ref(null)
    const store = useStore()

    return {
      isCreator: computed(() => store.getters.getId === store.getters.getProfileId),
      modalOpen,
      currentImage,
      open(img){
        modalOpen.value = true
        currentImage.value = img
      },
      close(){
        if(modalOpen.value === true) {
          modalOpen.value = false
          currentImage.value = null
        }
      },
    }
  }

}
</script>
