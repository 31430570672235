<template>
<div class="bg-white shadow-md mt-10">
  <DocumentTableHeader
      :form="form"
      @change:title="form.title=$event; $emit('search', form)"
      @change:department="form.department=$event; $emit('search', form)"
      @change:type="form.type=$event; $emit('search', form)"
  />
  <DocumentTableContent @sort="triggerSort" :documents="documents" :headers="headers" />
</div>
</template>

<script>
import DocumentTableHeader from "@/components/widgets/documentTable/DocumentTableHeader";
import DocumentTableContent from "@/components/widgets/documentTable/DocumentTableContent";
import {reactive} from "vue";
export default {
  name: "DocumentTable",
  components: {DocumentTableContent, DocumentTableHeader},
  props: {
    documents: Array,
    headers: Array
  },
  setup(props, { emit }) {
    const form = reactive({
      title: '',
      department: '',
      type: '',
      sortBy: ''
    })

    return {
      form,
      triggerSort(key){
        if(key === 'documentType') key = 'document_type'
        if(key === form.sortBy){
          key = '-' + key
        }
        form.sortBy = key
        emit('search', form)
      }
    }
  }
}
</script>
