<template>
  <div class="my-10 bg-white pb-1">
    <div v-if="!editModeActive" class="h-full">
        <div class="my-10">
          <div class="flex flex-col w-full">
            <div class="flex h-16 items-center w-full pl-6 pt-6">
              <PencilIcon v-if="edit" @click="editModeActive=true" class="h-2/4 mr-2 cursor-pointer"/>
              <h2 class="text-xl font-medium">Websites</h2>
            </div>
            <div v-for="website in websites" :key="website.id" class="underline w-full pl-6 pt-2">
              <a  target="_blank" :href="'//'+website.url" >{{website.title}}</a>
            </div >
          </div>
        </div>
    </div>
    <div v-else>
      <teleport to="body">
        <div class="bg-white fixed z-10 inset-x-80 inset-y-40 p-10 border-2 shadow-md overflow-y-auto flex justify-evenly items-start flex flex-col">
          <h1 class="font-medium">Websites</h1>
          <div class="w-full grid grid-cols-2">
            <p class="col-span-1">Link</p>
            <p class="col-span-1">Label</p>
            <div v-for="(website, idx) in websiteArray" :key="website.id" class="col-span-2 grid grid-cols-5 gap-4 items-center">
              <SmallTextInput :value="website.url" @update:value="website.url=$event" class="col-span-2"/>
              <SmallTextInput :value="website.title" @update:value="website.title=$event" class="col-span-2"/>
              <TrashIcon @click="removeWebsite(idx)" class="col-span-1 h-6"/>
            </div>
          </div>
            <button @click="addWebsite">+ Add website</button>
          <div class="flex justify-end w-full">
            <button @click="editModeActive=false" type="submit" class="mr-2 bg-white-600 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
              Cancel
            </button>
            <button @click="submit" class="bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
              Save
            </button>
          </div>
        </div>
      </teleport>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import {PencilIcon, TrashIcon} from '@heroicons/vue/outline'
import SmallTextInput from "@/components/inputs/SmallTextInput";
import {useStore} from "vuex";
export default {
  name: "ProductionWebsites",
  components: {SmallTextInput, PencilIcon, TrashIcon},
  props: {
    websites: Array,
    edit: Boolean
  },
  setup(props) {
    const store = useStore()
    const websiteArray = ref(props.websites)
    const editModeActive = ref(false)

    function addWebsite(){
      websiteArray.value.push({
        title: '',
        url: ''
      })
    }

    function removeWebsite(idx){
      let website = websiteArray.value[idx]
      if (website.id !== undefined){
        store.dispatch('removeWebsite', website.id)
      }
      websiteArray.value.splice(idx, 1)
    }

    return {
      editModeActive,
      websiteArray,
      removeWebsite,
      addWebsite,
      async submit() {
        for(let i=0; i < websiteArray.value.length; i++) {
          let website = websiteArray.value[i];

          if(website.id === undefined && website.url !== '' && website.title !== ''){
            let newWebsite = await store.dispatch('createWebsite', {...website, productionId:store.getters.getProductionId})
            websiteArray.value.push(newWebsite)
            removeWebsite(i)
          } else if (website.id !== undefined) {
            await store.dispatch('editWebsite', {websiteId:website.id, url:website.url, title:website.title})
          }
          editModeActive.value=false
        }
      }
    }

  }
}
</script>
