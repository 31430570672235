<template>
  <div class="p-2 bg-white my-5">
    <div v-if="!editModeActive">
      <div class="flex h-16 items-center w-full pl-6 pt-6">
        <PencilIcon v-if="edit" @click="editModeActive=true" class="h-2/4 mr-2 cursor-pointer"/>
        <h2 class="font-medium text-lg">Creative team</h2>
      </div>

      <div class="grid grid-cols-2 gap-6 py-5">
          <div v-for="p in peopleArray" :key="p.id" :class="[p.job.isPrimary ? '' : 'hidden']">
            <div v-if="p.job.isPrimary" class="flex h-28">
              <img src="https://picsum.photos/200/300" class="h-28 w-28 rounded-full"/>
              <div class="flex flex-col ml-5 justify-center items-center">
                <p class="text-lg font-medium">{{p.profile.fullName}}</p>
                <p>{{p.job.title}}</p>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-else>
      <teleport to="body">
        <div class=" mt-10 bg-white fixed z-10 inset-x-1/4 inset-y-20 p-10 border-2 shadow-md overflow-y-auto flex justify-evenly items-start flex flex-col">
          <h2 class="font-medium text-lg">Select who to show in overview</h2>
          <div class="grid grid-cols-2 gap-6 py-5 w-full">
            <div v-for="p in peopleArray" :key="p.id" @click="onSelect(p)" class="flex h-28" :class="[p.job.isPrimary? 'border-2' : '']">
              <img src="https://picsum.photos/200/300" class="h-28 w-28 rounded-full"/>
              <div class="flex flex-col ml-5 justify-center items-center">
                <p class="text-lg font-medium">{{p.profile.fullName}}</p>
                <p>{{p.job.title}}</p>
              </div>
            </div>
            <div class="col-span-2">
              <button @click="onCancel" type="submit" class="mr-2 bg-white-600 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
                Cancel
              </button>
              <button @click="onSubmit" class="bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
                Save
              </button>
          </div>
          </div>
        </div>
      </teleport>
        </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import {PencilIcon} from '@heroicons/vue/outline'

export default {
  name: "ProductionPeople",
  components: {PencilIcon},
  props: {
    people: Array,
    edit: Boolean
  },
  setup(props){
    const store = useStore()
    store.dispatch('fetchProductionMembers', {id:store.getters.getProductionId, searchFilter:''})

    const editModeActive = ref(false)
    const peopleArray = ref(props.people)
    const peopleToBeEdited = ref([])


    return {
      store,
      editModeActive,
      peopleArray,
      peopleToBeEdited,

      onSelect(person){
        person.job.isPrimary = !person.job.isPrimary
        if(peopleToBeEdited.value.indexOf(person) === -1) peopleToBeEdited.value.push(person)
      },
      async onSubmit(){
        for(let i=0; i < peopleToBeEdited.value.length; i++){
          let person = peopleToBeEdited.value[i];

          await store.dispatch('editProductionMember', {
            id: person.id,
            display: person.job.isPrimary
          })
        }
        peopleToBeEdited.value = []
        editModeActive.value=false
      },
      onCancel(){
        peopleToBeEdited.value = []
        editModeActive.value=false
      }
    }
  }
}
</script>
