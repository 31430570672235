<template>
  <div class="my-10 bg-white pb-1">
      <div v-if="!editModeActive">
        <div class="my-10 ">
          <div class="flex flex-col w-full">
            <div class="flex h-16 items-center w-full pl-6 pt-6">
              <PencilIcon v-if="edit" @click="editModeActive=true" class="h-2/4 mr-2 cursor-pointer"/>
              <h2 class="text-xl font-medium">Reviews</h2>
            </div>
            <a v-for="review in reviews" :key="review.id" target="_blank" :href="'//'+review.link" class="underline w-full pl-6 pt-2">{{review.author}}</a>
          </div>
        </div>
      </div>
    <div v-else>
      <teleport to="body">
        <div class="bg-white fixed z-10 inset-x-80 inset-y-40 p-10 border-2 shadow-md overflow-y-auto flex justify-evenly items-start flex flex-col">
          <h1 class="font-medium">Reviews</h1>
          <div class="w-full grid grid-cols-3">
            <p class="col-span-1">Link</p>
            <p class="col-span-1">Stars</p>
            <p class="col-span-1">Author</p>
            <div v-for="(review, idx) in reviewArray" :key="review.id" class="col-span-3 grid grid-cols-7 gap-4 items-center">
              <SmallTextInput :value="review.link" @update:value="review.link=$event" class="col-span-2"/>
              <SmallTextInput :value="review.stars" @update:value="review.stars=$event" class="col-span-2"/>
              <SmallTextInput :value="review.author" @update:value="review.author=$event" class="col-span-2"/>
              <TrashIcon @click="removeReview(idx)" class="col-span-1 h-6"/>
            </div>
          </div>
          <button @click="addReview">+ Add review</button>
          <div class="flex justify-end w-full">
            <button @click="editModeActive=false" type="submit" class="mr-2 bg-white-600 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
              Cancel
            </button>
            <button @click="submit" class="bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
              Save
            </button>
          </div>
        </div>
      </teleport>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import { PencilIcon, TrashIcon } from '@heroicons/vue/outline'
import SmallTextInput from "@/components/inputs/SmallTextInput";

export default {
  name: "ProductionReviews",
  components: {SmallTextInput, PencilIcon, TrashIcon },
  props: {
    reviews: Array,
    edit: Boolean
  },
  setup(props){
    const store = useStore()
    const reviewArray = ref(props.reviews)
    const editModeActive = ref(false)

    function addReview(){
      reviewArray.value.push({
        link: '',
        stars: '',
        author: ''
      })
    }

    function removeReview(idx){
      let review = reviewArray.value[idx]
      if (review.id !== undefined){
        store.dispatch('removeReview', review.id)
      }
      reviewArray.value.splice(idx, 1)
    }

    return {
      store,
      reviewArray,
      editModeActive,
      addReview,
      removeReview,
      async submit() {
        for(let i=0; i < reviewArray.value.length; i++){
          let review = reviewArray.value[i];

          if(review.id === undefined && review.link !== '' && review.stars !== '') {
            let newReview = await store.dispatch('createReview', {...review, productionId:store.getters.getProductionId})
            reviewArray.value.push(newReview)
            removeReview(i)
          } else if (review.id !== undefined) {
            await store.dispatch('editReview', {id:review.id, link:review.link, author:review.author, stars:review.stars})
          }
          editModeActive.value=false
        }
      }
    }
  }
}
</script>
