<template>
  <div @click="$emit('close')" class="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center bg-opacity-70 bg-gray-400">asd
    <img class="inset-50 z-30 absolute w-auto h-2/3" :src="image">
  </div>
</template>

<script>
export default {
  name: "PhotoGalleryModal",
  props: {
    image: String
  }
}
</script>
