<template>
  <tr class="py-4 whitespace-nowrap text-sm font-medium text-gray-900  ">
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white" v-for="(field, idx) in fields" :key="idx">
      <div class="font-medium">{{ getAttribute(field) }}</div>
    </td>
    <td><a :href="item.file" target="_blank" download><DownloadIcon class="w-6" /></a></td>
  </tr>
</template>
<script>
import { DownloadIcon } from '@heroicons/vue/solid'
export default {
  name: "DocumentTableUnit",
  components: {DownloadIcon},
  props: {
    fields: Array,
    item: Object
  },
  methods: {
    getAttribute: function(name){
      for (let prop in this.item){
        if (prop === name || prop === name.toLowerCase()){
          if (this.item[prop] === '') return '-'
          if(typeof this.item[prop] === 'object') {
            try {
              return this.item[prop].join(', ')   // If prop is an Array
            } catch (e) {
              return this.item[prop]
            }
          }
          return this.item[prop]
        }
      }
      return '-'
    }
  }
}
</script>
